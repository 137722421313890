import Button from 'components/Button';
import DataTable from 'components/DataTable';
import Input from 'components/Input';
import ListActionsWrapper from 'components/ListActionsWrapper';
import ListTabs from 'components/ListTabs';
import ListWrapper from 'components/ListWrapper';
import Modal from 'components/Modal';
import Pagination from 'components/Pagination';
import ProductImage from 'components/ProductImage';
import SearchInput from 'components/SearchInput';
import Typography from 'components/Typography';
import {ProductTypeFilter} from 'hooks/useProductList';
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {DataItem, Product} from 'types';
import styles from './Product.module.scss';
import api from 'api';
import toast from 'react-hot-toast';
import {get} from 'lodash';
import Select from 'components/Select';
import FormActionGroup from 'components/FormActionGroup';
import {Controller, useForm} from 'react-hook-form';

const SCHEMA = [
  {dataKey: 'id', header: 'Product ID', colWidth: '120px', sortable: true},
  {dataKey: 'image', header: 'Image', colWidth: '200px'},
  {dataKey: 'name', header: 'Name', colWidth: '2fr', sortable: true},
  // {dataKey: 'strength', header: 'Strength'},
  // {dataKey: 'description', header: 'Description'},
  {dataKey: 'price', header: 'Price', sortable: true},
  {dataKey: 'total_prescriptions', header: 'Count', sortable: true},
];

const tabs = [
  {title: 'All', value: ''},
  {title: 'OTC', value: 'otc'},
  {title: 'Prescription', value: 'prescription'},
  {title: 'Featured', value: 'featured'},
];

type Props = {
  products: Product[];
  totalPages: number;
  currentPage?: number;
  search?: string;
  refetch: () => void;
  onProductTypeChange: (value: ProductTypeFilter) => void;
  productType?: ProductTypeFilter;
  onSearchChange: (searchValue: string) => void;
  sortBy: string;
  sortOrder: 'desc' | 'asc';
  count?: number;
  onSort: (sort: string, orderBy: 'desc' | 'asc') => void;
};

const Products: React.VFC<Props> = ({
  products,
  productType = '',
  onProductTypeChange,
  totalPages,
  search = '',
  refetch,
  onSearchChange,
  currentPage = 1,
  sortBy,
  sortOrder,
  onSort,
  count,
}) => {
  const [visible, setVisible] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState<Product>();

  const handleSearchChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      onSearchChange(e.target.value);
    },
    [onSearchChange]
  );

  const handleSelectProduct = useCallback(
    (dataItem: DataItem) => {
      const product = products.find((item) => item.id === dataItem.id);
      setSelectedProduct(product);
    },
    [products]
  );

  const data = useMemo(() => {
    return products.map((product) => ({
      ...product,
      id: product.id,
      image: <ProductImage product={product} />,
      name: product.name,
      strength: product.strength?.replaceAll('/', ' / '),
      description: product.description,
      price: product.price ? '$' + product.price : '',
    }));
  }, [products]);

  const onChangeTabs = useCallback(
    (nextValue: string) => {
      onProductTypeChange(nextValue as ProductTypeFilter);
    },
    [onProductTypeChange]
  );

  const deleteProduct = async () => {
    try {
      await api.delete(`/products/${selectedProduct?.id}`);
      refetch();
      setSelectedProduct(undefined);
    } catch (error) {
      toast.error(get(error, 'message', ''));
    }
  };

  return (
    <>
      <div style={{display: 'flex', justifyContent: 'space-between'}}>
        <Typography variant="h2">All products</Typography>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-end',
            gap: 10,
          }}
        >
          <Button onClick={() => setVisible(true)} color="blue">
            Add Product
          </Button>
          <Typography textAlign="right" variant="h4">
            Total Product count: {count}
          </Typography>
        </div>
      </div>
      <ListWrapper>
        <ListTabs
          tabs={tabs}
          isProductsPage={true}
          activeTabValue={productType}
          onChange={onChangeTabs}
        />
        <ListActionsWrapper>
          <SearchInput
            placeholder="Search"
            onChange={handleSearchChange}
            value={search}
          />
        </ListActionsWrapper>
        <DataTable
          data={data}
          schema={SCHEMA}
          selectable={false}
          sortBy={sortBy}
          sortOrder={sortOrder}
          onSort={onSort}
          onClick={handleSelectProduct}
        />
        <Pagination
          basePath="/products"
          page={currentPage}
          total={totalPages}
          setCurrentPage={() => {}}
        />
      </ListWrapper>
      <NewProduct
        onDelete={deleteProduct}
        visible={visible || !!selectedProduct}
        product={selectedProduct}
        onClose={() => {
          setSelectedProduct(undefined);
          setVisible(false);
        }}
        onSuccess={() => {
          refetch();
        }}
      />
    </>
  );
};

type ProductProps = {
  visible: boolean;
  onClose: () => void;
  onSuccess: (data: Product) => void;
  product?: Product;
  onDelete?: () => void;
};

export const NewProduct = ({
  visible,
  onClose,
  onSuccess,
  product,
  onDelete,
}: ProductProps) => {
  const {control, handleSubmit, reset, setValue} = useForm();
  const [description, setDescription] = useState('');
  const [loading, setLoading] = useState(false);
  const [image, setImage] = useState<any>(null);

  useEffect(() => {
    if (product) {
      setValue('name', product.name);
      setValue('price', product.price);
      setValue('strength', product.strength);
      setDescription(product.description);
      setValue(
        'is_prescription',
        product.is_prescription !== null
          ? {
              value: product?.is_prescription ? 'true' : 'false',
              label: product?.is_prescription ? 'Prescription' : 'OTC',
            }
          : undefined
      );
    } else {
      setDescription('');
      reset();
      setImage(null);
    }
  }, [product, reset, setValue]);

  const onSubmit = (data: any) => {
    setLoading(true);
    const formData = new FormData();
    formData.append('name', data.name);
    formData.append(
      'is_prescription',
      data?.is_prescription !== null ? data?.is_prescription?.value : ''
    );
    formData.append('price', data.price);
    formData.append('description', description);
    formData.append('strength', data.strength);

    if (image) {
      formData.append('image.file', image);
    }

    const request = product
      ? api.put(`/products/${product.id}/`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
      : api.post('products/', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });

    request
      .then((res) => {
        onSuccess(res.data);
        setDescription('');
        setImage(null);
        reset();
        onClose();
      })
      .catch((err) => {
        toast.error(err.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Modal
      isOpen={visible}
      onClose={onClose}
      className={styles.list_wrapper}
      onRequestClose={onClose}
    >
      <Typography variant="h2">
        {product ? product.name : 'Add new product'}
      </Typography>
      <div className={styles.label}>Image:</div>
      <ProductImage
        size="medium"
        product={product}
        onChange={(value) => {
          setImage(value);
        }}
      />
      <div className={styles.label}>Name:</div>
      <Controller
        name="name"
        control={control}
        rules={{required: true}}
        render={({field, fieldState}) => (
          <Input
            {...field}
            placeholder="Name"
            hasError={!!fieldState.error}
            className={styles.root}
          />
        )}
      />
      <div className={styles.label}>Strength</div>
      <Controller
        name="strength"
        control={control}
        rules={{required: true}}
        render={({field, fieldState}) => (
          <Input
            {...field}
            placeholder="strength"
            hasError={!!fieldState.error}
            className={styles.root}
          />
        )}
      />
      <div className={styles.label}>Description:</div>
      <textarea
        value={description}
        onChange={(event) => {
          setDescription(event.target.value);
        }}
        placeholder="Description"
        className={styles.textarea}
      />
      <div className={styles.label}>Price:</div>
      <Controller
        name="price"
        control={control}
        rules={{
          required: {
            value: true,
            message: 'Required',
          },
        }}
        render={({field, fieldState}) => (
          <Input
            {...field}
            hasError={!!fieldState.error?.message}
            onChange={(value) => {
              let val = value.target.value;
              let dec = '';
              if (val.split('.').length > 1) {
                dec = '.' + val.split('.')[1].replace(/\D/g, '');
                val = val.split('.')[0];
              }
              field.onChange(
                val.replace(/\D/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, ' ') +
                  `${dec}`
              );
            }}
            placeholder="Price"
            className={styles.root}
          />
        )}
      />
      {!product && (
        <>
          <div className={styles.label}>Click to select</div>
          <Controller
            name="is_prescription"
            control={control}
            render={({field, fieldState}) => (
              <Select
                {...field}
                error={fieldState.error?.message}
                options={[
                  {
                    label: 'Prescription',
                    value: 'true',
                  },
                  {
                    label: 'OTC',
                    value: 'false',
                  },
                ]}
              />
            )}
          />
        </>
      )}

      <FormActionGroup className={styles.buttonWrap}>
        {product ? (
          <Button color="danger" size="md" onClick={onDelete}>
            Delete
          </Button>
        ) : (
          <Button onClick={onClose} component="a" color="gray">
            Cancel
          </Button>
        )}
        <Button
          loading={loading}
          onClick={handleSubmit(onSubmit)}
          color="green"
        >
          {product ? 'Update product' : 'Add Product'}
        </Button>
      </FormActionGroup>
    </Modal>
  );
};

export default Products;
