import Spinner from 'components/Spinner';
import Card from 'components/Card';
import CardContent from 'components/CardContent';
import Note from 'components/Note';
import NoteForm from 'components/NoteForm';
import ImageView from 'components/ImageView';

import CustomerDetails from './components/CustomerDetails';
import DoctorDetails from './components/DoctorDetails';
import Header from './components/Header';
import Prescriptions from './components/Prescriptions';
import PreferenceTime from './components/PreferenceTime';
import Otcs from './components/Otcs';
import useRequestDetails from './useRequestDetails';
import useUpdateDeliveryAddress from './useUpdateDeliveryAddress';
import ListWrapper from 'components/ListWrapper';
import styles from './RequestDetails.module.scss';
import useSendCustomerNotification from 'hooks/useSendCustomerNotification';
import React, {Key, useCallback, useEffect, useState} from 'react';
import toast from 'react-hot-toast';
import useRequestSignature from '../../hooks/useRequestSignature';
import Modal from '../../components/Modal';
import Typography from '../../components/Typography';
import {get} from 'lodash';
import Button from '../../components/Button';
import FormGroup from 'components/FormGroup';
import {Controller, useForm} from 'react-hook-form';
import {
  GooglePlaceAutoComplete,
  TypeGooglePlaceDetail,
} from 'components/GooglePlaceAutoComplete';
import TextField from 'components/TextField';
import useUserForm from 'pages/UserForm/useUserForm';
import {createAddress} from 'types';
import ArrowIcon from 'pages/UserForm/UserAddresses/ArrowIcon';
import MapPreview from 'components/MapPreview';

type FormData = Omit<createAddress, 'label'>;

// @ts-ignore
function RequestDetails() {
  const [status, setStatus] = useState('');
  const {
    id,
    showInsurance,
    data,
    markDisabled,
    setShowInsurance,
    onChangeScriptStatus,
    onSubmitNote,
    complete,
    chargeCard,
    onChangeTime,
  } = useRequestDetails(status);

  const {addressIsUpdating, updateDeliveryAddress} = useUpdateDeliveryAddress();

  const {sendNotification} = useSendCustomerNotification();
  const {requestSignature} = useRequestSignature();
  const [modalOpen, setModalOpen] = useState(false);
  const [openAddressModal, setOpenAddressModal] = useState(false);
  const [selectCardModal, setSelectCardModal] = useState(false);
  const [accessCardModal, setAccessCardModal] = useState(false);
  const [deliveryAddressId, setDeliveryAddressId] = useState(
    data?.delivery_address?.id
  );
  const [cardId, setCardIs] = useState(0);

  const {control, setValue, handleSubmit, reset} = useForm<FormData>();
  const {createNewAddress, cards} = useUserForm(data?.customer.id);

  useEffect(() => {
    if (cards.length > 0) {
      setCardIs(cards[0].custom_uuid);
    }
  }, [cards]);

  const handleCreation = async (datas: createAddress) => {
    const res = await createNewAddress({
      ...datas,
      customer_id: data?.customer.id,
    });

    if (get(res, 'status') === 200) {
      setOpenAddressModal(false);
      setDeliveryAddressId(res.data.id);
      addressChangeHandler(res.data.id);
      reset();
      setLocation({
        latitude: 40.7596958,
        longitude: -73.9650045,
      });
    } else {
      toast.error('Something wrong with creation. Try again later !');
    }
  };

  useEffect(() => {
    if (data?.request_origin.startsWith('app')) {
      if (data?.prescriptions.length) {
        setStatus(data.prescriptions[0].status);
      }
    } else {
      if (data?.otcs.length) {
        setStatus(data.otcs[0].status);
      }
    }
  }, [data?.id, data?.otcs, data?.request_origin, data?.prescriptions]);

  const handleNotifyCustomer = useCallback(async () => {
    if (!data?.id) {
      return;
    }

    if (data.prescriptions.length > 0) {
      await sendNotification({
        requestId: data?.id,
        request_drugs:
          status === 'completed'
            ? data.prescriptions.filter((item) => item.status !== 'completed')
            : data.prescriptions,
        // : data.prescriptions.filter((item) => item.status !== 'billed'),
      });
      toast.success('Notification sent successfully');
    }
  }, [data?.id, sendNotification, status, data?.prescriptions]);

  const onCharge = async () => {
    if (cards.length < 1) {
      toast.error('Please add billing card to user first');
      return;
    }
    if (cards.length > 1) {
      setSelectCardModal(true);
      return;
    }
    setAccessCardModal(true);
  };

  const submitSelectedCard = async () => {
    if (!cardId) {
      return;
    }
    try {
      await chargeCard({
        customer: data?.customer.id,
        payment_method_uuid: cardId,
        copay_items: get(data, 'prescriptions', []),
      });
      setAccessCardModal(false);
      setSelectCardModal(false);
      toast.success('Success');
    } catch (error) {
      toast.error('Error try again');
    }
  };

  const handleRequestSignature = useCallback(async () => {
    if (!data?.id) {
      return;
    }
    await requestSignature({
      requestId: data?.id,
    });
    toast.success('Notification sent successfully');
  }, [data?.id, requestSignature]);

  const addressChangeHandler = async (id: number | string) => {
    updateDeliveryAddress({
      requestId: data?.id,
      addressId: id,
    })
      .then((res) => {
        // onSubmitNote('');
        handleModalClose();
      })
      .catch((er) => {
        const message = get(
          er,
          'response.data.error_message',
          "You can't change address!"
        );
        toast.error(message || "You can't change address!");
      });
  };

  function modalHandler() {
    setDeliveryAddressId(data?.delivery_address.id);
    setModalOpen(true);
  }
  const handleModalClose = () => setModalOpen(false);

  const [location, setLocation] = useState({
    latitude: 40.7596958,
    longitude: -73.9650045,
  });

  const onSelectPlace = (placeDetail: TypeGooglePlaceDetail) => {
    setValue('latitude', placeDetail.lat);
    setValue('longitude', placeDetail.lon);
    setTimeout(() => {
      setLocation({latitude: placeDetail.lat, longitude: placeDetail.lon});
    }, 300);
    setValue('zipcode', placeDetail.zipcode);
    setValue('address_line_1', placeDetail.addressLine1);
    setValue('city', placeDetail.city);
    setValue('state', placeDetail.state);
  };

  const selectedCard = cards.find((item) => item.custom_uuid === cardId);

  if (!data)
    return (
      <div
        style={{
          display: 'flex',
          width: '100%',
          height: '100vh',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Spinner />
      </div>
    );

  return (
    <div>
      <Header
        id={id}
        created_at={data.created_at}
        status={data.status}
        app={data.request_origin}
        completeDisabled={markDisabled}
        complete={complete}
        rx_no={
          data.prescriptions.length > 0
            ? get(data.prescriptions[0], 'rx_no', 0)
            : data.otcs.length > 0
            ? get(data.otcs[0], 'rx_no', 0)
            : 0
        }
        previous_id={data.previous_id}
        next_id={data.next_id}
      />

      <div className={styles.content}>
        <div className={styles.main_content}>
          <CustomerDetails
            pharmacy={data.pharmacy}
            customer={data.customer}
            isOrder={!data.request_origin.startsWith('app')}
            deliveryData={data?.delivery_address}
            modalHandler={modalHandler}
          />
          <DoctorDetails
            request={data}
            showInsurance={() => setShowInsurance(true)}
          />
          <Card>
            <CardContent dark>
              <NoteForm onSubmit={onSubmitNote} />
              {data.notes.map((note, index) => (
                <Note
                  key={note.id}
                  {...note}
                  text={note.text}
                  isCompleted={
                    get(data, 'status', '') === 'completed' &&
                    index === 0 &&
                    !!get(data, 'route_id', null)
                  }
                  order_id={data.id}
                  route_id={Number(get(data, 'route_id', null))}
                />
              ))}
            </CardContent>
          </Card>
        </div>
        <div className={styles.drugs}>
          {data.prescriptions.length > 0 ? (
            <Prescriptions
              requestId={data.id}
              origin={data.request_origin}
              drugs={data.prescriptions}
              status={status}
              onChangeStatus={(newVal, action) => {
                setStatus(get(newVal, 'value', ''));
                onChangeScriptStatus(newVal, action);
              }}
              sync_source={data.sync_source}
              onCharge={onCharge}
              onSendNotification={handleNotifyCustomer}
              onRequestSignature={handleRequestSignature}
            />
          ) : (
            ''
          )}
          <Otcs
            requestId={data.id}
            drugs={data.otcs}
            origin={data.request_origin}
            onChangeStatus={onChangeScriptStatus}
          />
          {!data.request_origin.startsWith('app') && (
            <PreferenceTime
              onChangeTime={onChangeTime}
              preference_time={data.preference_time}
              preference_date={data.preference_date}
            />
          )}
        </div>
      </div>
      <Modal
        isOpen={modalOpen}
        onClose={handleModalClose}
        className={styles.list_wrapper}
      >
        <Typography component="p" className={styles.modalDesc} variant="h2">
          Select Delivery Address:
        </Typography>
        <div className={styles.optionWrapper}>
          {get(data.customer, 'delivery_addresses', []).map(
            (item: any, index: Key | null | undefined) => {
              return (
                <label key={index} className={styles.container}>
                  <input
                    type="radio"
                    name="radio"
                    defaultChecked={deliveryAddressId === item.id}
                    onClick={() => setDeliveryAddressId(item.id)}
                  />
                  <span className={styles.radioButtonText}>
                    {get(item, 'address_line_1', '')}, {get(item, 'city', '')},{' '}
                    {get(item, 'state', '')}, {get(item, 'zipcode', '')}
                    {get(item, 'unit_address', '')
                      ? `, ${get(item, 'unit_address', '')}`
                      : ''}
                  </span>
                  {!get(item, 'is_valid', true) && (
                    <span className={styles.wrongAddress}>issue</span>
                  )}
                </label>
              );
            }
          )}
          <div
            onClick={() => setOpenAddressModal((pr) => !pr)}
            className={styles.addAddress}
          >
            <p>+ Add address</p>
            <ArrowIcon
              className={
                openAddressModal ? styles.icon_rotated : styles.arror_icon
              }
            />
          </div>
          {openAddressModal && (
            <ListWrapper
              style={{
                marginTop: 10,
                paddingLeft: 20,
                paddingRight: 20,
                paddingBottom: 15,
              }}
            >
              {/* <Typography component="p" variant="h4">
                Add New Address:
              </Typography> */}
              <FormGroup>
                <Controller
                  name="city"
                  control={control}
                  rules={{required: 'Please, Select the address'}}
                  render={({field, fieldState}) => (
                    <GooglePlaceAutoComplete
                      label="Your address"
                      placeholder="Address"
                      onSelectPlace={onSelectPlace}
                      error={fieldState.error?.message}
                      options={{
                        types: ['address'],
                      }}
                    />
                  )}
                />
                <Controller
                  name="unit_address"
                  control={control}
                  render={({field, fieldState}) => (
                    <TextField
                      label="Unit number"
                      wrapperClass={styles.googleInput}
                      placeholder="Enter unit number"
                      hasError={false}
                      onChange={field.onChange}
                      error={fieldState.error?.message}
                    />
                  )}
                />
              </FormGroup>
              <div
                style={{
                  alignItems: 'center',
                  display: 'flex',
                  flexDirection: 'column',
                  paddingTop: 20,
                }}
              >
                {!!location.latitude && (
                  <MapPreview
                    zoom={15}
                    onDrag={(location) => {
                      setValue('latitude', location.lat);
                      setValue('longitude', location.lon);
                    }}
                    center={[location.latitude, location.longitude]}
                  />
                )}
              </div>

              <div className={styles.actionWrapper}>
                {/* <Button
                  className={styles.cancelButton}
                  onClick={() => {
                    setOpenAddressModal(false);
                  }}
                  component="a"
                  color="gray"
                >
                  Cancel
                </Button> */}
                <Button
                  type="submit"
                  onClick={handleSubmit(handleCreation)}
                  color="green"
                >
                  Add
                </Button>
              </div>
            </ListWrapper>
          )}
        </div>
        <div className={styles.actions}>
          <Button color="outlined" size="md" onClick={handleModalClose}>
            Close
          </Button>
          <Button
            type="submit"
            color="green"
            onClick={() => addressChangeHandler(deliveryAddressId || 0)}
            size="md"
            loading={addressIsUpdating}
          >
            Submit
          </Button>
        </div>
      </Modal>
      {data.insurance &&
        data.insurance.front_image &&
        data.insurance.back_image && (
          <ImageView
            show={showInsurance}
            onClose={() => setShowInsurance(false)}
            images={[
              data.insurance.front_image.file,
              data.insurance.back_image.file,
            ]}
          />
        )}
      {/* <Modal
        isOpen={false}
        onClose={() => setOpenAddressModal(false)}
        className={styles.list_wrapper}
      >
        <div className={styles.modalWrapper}>
          <FormGroup>
            <Controller
              name="city"
              control={control}
              rules={{required: 'Please, Select the address'}}
              render={({field, fieldState}) => (
                <GooglePlaceAutoComplete
                  label="Your address"
                  placeholder="Address"
                  onSelectPlace={onSelectPlace}
                  error={fieldState.error?.message}
                  options={{
                    types: ['address'],
                  }}
                />
              )}
            />
            <Controller
              name="unit_address"
              control={control}
              render={({field, fieldState}) => (
                <TextField
                  label="Unit number"
                  wrapperClass={styles.googleInput}
                  placeholder="Enter unit number"
                  hasError={false}
                  onChange={field.onChange}
                  error={fieldState.error?.message}
                />
              )}
            />
          </FormGroup>

          <div className={styles.actionWrapper}>
            <Button
              className={styles.cancelButton}
              onClick={() => {
                setOpenAddressModal(false);
              }}
              component="a"
              color="gray"
            >
              Cancel
            </Button>
            <Button
              type="submit"
              onClick={handleSubmit(handleCreation)}
              color="green"
            >
              Add
            </Button>
          </div>
        </div>
      </Modal> */}
      <Modal
        isOpen={selectCardModal}
        onClose={() => setSelectCardModal(false)}
        className={styles.list_wrapper}
      >
        <Typography component="p" className={styles.modalDesc} variant="h2">
          Select Card to Charge:
        </Typography>
        <div className={styles.optionWrapper}>
          {cards.map((item: any, index: Key | null | undefined) => {
            return (
              <label key={index} className={styles.radioOption}>
                <input
                  type="radio"
                  name="radio"
                  defaultChecked={cardId === item.custom_uuid}
                  onClick={() => setCardIs(item.custom_uuid)}
                />
                {/* <div className={styles.column}> */}
                <span className={styles.radioButtonText}>
                  {get(item, 'cardholder_name', '')}
                </span>
                <p
                  style={{marginLeft: 'auto'}}
                  className={styles.radioButtonText}
                >
                  **** **** **** {get(item, 'last4', '')}
                </p>
                {/* </div> */}
              </label>
            );
          })}
        </div>
        <div className={styles.actions}>
          <Button
            color="outlined"
            size="md"
            onClick={() => setSelectCardModal(false)}
          >
            Close
          </Button>
          <Button
            type="submit"
            color="green"
            onClick={() => setAccessCardModal(true)}
            size="md"
            loading={addressIsUpdating}
          >
            Submit
          </Button>
        </div>
      </Modal>
      <Modal
        isOpen={accessCardModal}
        onClose={() => setAccessCardModal(false)}
        className={styles.list_wrapper}
      >
        <Typography component="p" className={styles.modalDesc} variant="h2">
          Are you sure you want to charge this card?
        </Typography>
        <div className={styles.card}>
          <div className={styles.item_header}>
            <div>{get(selectedCard, 'cardholder_name', '')}</div>
          </div>
          <div className={styles.row}>
            <div>**** **** **** {get(selectedCard, 'last4', '')}</div>
            <div>
              Expiration: {get(selectedCard, 'exp_month', '')}/
              {get(selectedCard, 'exp_year', '')}
            </div>
          </div>
        </div>
        <div className={styles.actions}>
          <Button
            color="outlined"
            size="md"
            onClick={() => setAccessCardModal(false)}
          >
            Close
          </Button>
          <Button
            type="submit"
            color="green"
            onClick={submitSelectedCard}
            size="md"
            loading={addressIsUpdating}
          >
            Submit
          </Button>
        </div>
      </Modal>
    </div>
  );
}

export default RequestDetails;
